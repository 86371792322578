ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 0;
    /* width: 100%; */
    /* float: left; */
}

li {
display: inline;
float: left;
}

a {
display: block;
color: white;
padding: 8px;
/* background-color: #dddddd; */
}

li a:hover {
color: var(--light-green)
}

a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }