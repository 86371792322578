:root {
  --dark-green: #004643;
  --red: #E16162;
  --yellow: #F9BC60;
  --light-green: #ABD1C6;
  --white: #EFF0F3;
}

Body {
  background-color: var(--dark-green)
}

.App {
  text-align: center;
}

::selection {
  color: var(--yellow);
  background: black;
}
